var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "Card",
        [
          _c(
            "Row",
            { staticClass: "operation" },
            [
              _c("span", [_vm._v("组件英文名：")]),
              _c(
                "Tooltip",
                {
                  attrs: {
                    content:
                      "生成的请求api文件中将使用该组件名拼接，建议驼峰法命名",
                    placement: "right",
                    transfer: "",
                    "max-width": "250",
                  },
                },
                [
                  _c("Input", {
                    staticStyle: { width: "150px", "margin-right": "10px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.vueName,
                      callback: function ($$v) {
                        _vm.vueName = $$v
                      },
                      expression: "vueName",
                    },
                  }),
                ],
                1
              ),
              _c("span", [_vm._v("表单显示：")]),
              _c(
                "Select",
                {
                  staticStyle: { width: "150px", "margin-right": "10px" },
                  model: {
                    value: _vm.rowNum,
                    callback: function ($$v) {
                      _vm.rowNum = $$v
                    },
                    expression: "rowNum",
                  },
                },
                [
                  _c("Option", { attrs: { value: "1" } }, [_vm._v("一行一列")]),
                  _c("Option", { attrs: { value: "2" } }, [_vm._v("一行两列")]),
                  _c("Option", { attrs: { value: "3" } }, [_vm._v("一行三列")]),
                  _c("Option", { attrs: { value: "4" } }, [_vm._v("一行四列")]),
                ],
                1
              ),
              _c(
                "Button",
                {
                  attrs: { type: "primary", icon: "md-add" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加字段")]
              ),
              _c(
                "Button",
                {
                  attrs: { type: "warning", icon: "ios-construct" },
                  on: {
                    click: function ($event) {
                      _vm.entityModal = true
                    },
                  },
                },
                [_vm._v("读取字段")]
              ),
              _c(
                "Button",
                {
                  attrs: {
                    loading: _vm.loading,
                    type: "success",
                    icon: "md-send",
                  },
                  on: { click: _vm.generate },
                },
                [_vm._v("生成代码")]
              ),
              _c(
                "Button",
                { attrs: { icon: "md-trash" }, on: { click: _vm.clear } },
                [_vm._v("清空全部")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Alert", { attrs: { "show-icon": "" } }, [
                _vm._v(
                  " 将生成的代码复制粘贴至XBoot前端新建的空白组件中，再做少许修改并自行调通接口即可 "
                ),
                _c(
                  "span",
                  { staticClass: "preview", on: { click: _vm.preview } },
                  [_vm._v("增删改表格预览")]
                ),
              ]),
            ],
            1
          ),
          _c(
            "Row",
            [
              _c("Table", {
                ref: "table",
                attrs: { border: "", columns: _vm.columns, data: _vm.data },
                scopedSlots: _vm._u([
                  {
                    key: "field",
                    fn: function ({ row }) {
                      return [
                        _c("Input", {
                          on: {
                            "on-blur": function ($event) {
                              return _vm.changeField(row, $event)
                            },
                          },
                          model: {
                            value: row.field,
                            callback: function ($$v) {
                              _vm.$set(row, "field", $$v)
                            },
                            expression: "row.field",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "name",
                    fn: function ({ row }) {
                      return [
                        _c("Input", {
                          on: {
                            "on-blur": function ($event) {
                              return _vm.changeName(row, $event)
                            },
                          },
                          model: {
                            value: row.name,
                            callback: function ($$v) {
                              _vm.$set(row, "name", $$v)
                            },
                            expression: "row.name",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "tableShow",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "i-switch",
                          {
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeTableShow(row, $event)
                              },
                            },
                            model: {
                              value: row.tableShow,
                              callback: function ($$v) {
                                _vm.$set(row, "tableShow", $$v)
                              },
                              expression: "row.tableShow",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "open" }, slot: "open" },
                              [_vm._v("开")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "close" }, slot: "close" },
                              [_vm._v("关")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "editable",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "i-switch",
                          {
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeEditable(row, $event)
                              },
                            },
                            model: {
                              value: row.editable,
                              callback: function ($$v) {
                                _vm.$set(row, "editable", $$v)
                              },
                              expression: "row.editable",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "open" }, slot: "open" },
                              [_vm._v("开")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "close" }, slot: "close" },
                              [_vm._v("关")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "type",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "Select",
                          {
                            attrs: { disabled: !row.editable, transfer: "" },
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeType(row, $event)
                              },
                            },
                            model: {
                              value: row.type,
                              callback: function ($$v) {
                                _vm.$set(row, "type", $$v)
                              },
                              expression: "row.type",
                            },
                          },
                          [
                            _c("Option", { attrs: { value: "text" } }, [
                              _vm._v("文本输入框"),
                            ]),
                            _c("Option", { attrs: { value: "textarea" } }, [
                              _vm._v("多行文本输入框"),
                            ]),
                            _c("Option", { attrs: { value: "select" } }, [
                              _vm._v("选择下拉框"),
                            ]),
                            _c("Option", { attrs: { value: "number" } }, [
                              _vm._v("数字输入框"),
                            ]),
                            _c("Option", { attrs: { value: "dict" } }, [
                              _vm._v("数据字典选择组件"),
                            ]),
                            _c("Option", { attrs: { value: "customList" } }, [
                              _vm._v("自定义列表选择组件"),
                            ]),
                            _c("Option", { attrs: { value: "date" } }, [
                              _vm._v("日期选择器"),
                            ]),
                            _c("Option", { attrs: { value: "datetime" } }, [
                              _vm._v("日期时间选择器"),
                            ]),
                            _c("Option", { attrs: { value: "daterange" } }, [
                              _vm._v("日期范围选择器"),
                            ]),
                            _c("Option", { attrs: { value: "upload" } }, [
                              _vm._v("图片上传输入组件"),
                            ]),
                            _c("Option", { attrs: { value: "uploadThumb" } }, [
                              _vm._v("图片上传缩略图组件"),
                            ]),
                            _c("Option", { attrs: { value: "switch" } }, [
                              _vm._v("Switch开关"),
                            ]),
                            _c("Option", { attrs: { value: "radio" } }, [
                              _vm._v("Radio单选框"),
                            ]),
                            _c("Option", { attrs: { value: "time" } }, [
                              _vm._v("时间选择器"),
                            ]),
                            _c("Option", { attrs: { value: "area" } }, [
                              _vm._v("省市县级联"),
                            ]),
                            _c("Option", { attrs: { value: "slider" } }, [
                              _vm._v("Slider滑块"),
                            ]),
                            _c("Option", { attrs: { value: "quill" } }, [
                              _vm._v("富文本组件Quill"),
                            ]),
                            _c("Option", { attrs: { value: "password" } }, [
                              _vm._v("密码强度提示输入框"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "validate",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "i-switch",
                          {
                            attrs: { disabled: !row.editable },
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeValidate(row, $event)
                              },
                            },
                            model: {
                              value: row.validate,
                              callback: function ($$v) {
                                _vm.$set(row, "validate", $$v)
                              },
                              expression: "row.validate",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "open" }, slot: "open" },
                              [_vm._v("是")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "close" }, slot: "close" },
                              [_vm._v("否")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "searchable",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "i-switch",
                          {
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeSearchable(row, $event)
                              },
                            },
                            model: {
                              value: row.searchable,
                              callback: function ($$v) {
                                _vm.$set(row, "searchable", $$v)
                              },
                              expression: "row.searchable",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "open" }, slot: "open" },
                              [_vm._v("开")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "close" }, slot: "close" },
                              [_vm._v("关")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "searchType",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "Select",
                          {
                            attrs: { disabled: !row.searchable, transfer: "" },
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeSearchType(row, $event)
                              },
                            },
                            model: {
                              value: row.searchType,
                              callback: function ($$v) {
                                _vm.$set(row, "searchType", $$v)
                              },
                              expression: "row.searchType",
                            },
                          },
                          [
                            _c("Option", { attrs: { value: "text" } }, [
                              _vm._v("文本输入框"),
                            ]),
                            _c("Option", { attrs: { value: "select" } }, [
                              _vm._v("选择下拉框"),
                            ]),
                            _c("Option", { attrs: { value: "dict" } }, [
                              _vm._v("数据字典选择组件"),
                            ]),
                            _c("Option", { attrs: { value: "customList" } }, [
                              _vm._v("自定义列表选择组件"),
                            ]),
                            _c(
                              "Option",
                              {
                                attrs: {
                                  value: "daterange",
                                  disabled: _vm.daterangeSearch,
                                },
                              },
                              [_vm._v("日期范围选择器 ")]
                            ),
                            _c("Option", { attrs: { value: "date" } }, [
                              _vm._v("日期选择器"),
                            ]),
                            _c("Option", { attrs: { value: "area" } }, [
                              _vm._v("省市县级联"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "sortable",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "i-switch",
                          {
                            on: {
                              "on-change": function ($event) {
                                return _vm.changeSortable(row, $event)
                              },
                            },
                            model: {
                              value: row.sortable,
                              callback: function ($$v) {
                                _vm.$set(row, "sortable", $$v)
                              },
                              expression: "row.sortable",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "open" }, slot: "open" },
                              [_vm._v("开")]
                            ),
                            _c(
                              "span",
                              { attrs: { slot: "close" }, slot: "close" },
                              [_vm._v("关")]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "action",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.edit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("Divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.remove(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: { title: _vm.modalTitle, "mask-closable": false, width: 750 },
          model: {
            value: _vm.modalVisible,
            callback: function ($$v) {
              _vm.modalVisible = $$v
            },
            expression: "modalVisible",
          },
        },
        [
          _c(
            "Form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": 100,
                rules: _vm.formValidate,
                inline: "",
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "字段英文名", prop: "field" } },
                [
                  _c(
                    "Tooltip",
                    {
                      attrs: {
                        placement: "right",
                        content: "与后端实体字段匹配",
                      },
                    },
                    [
                      _c("Input", {
                        staticStyle: { width: "245px" },
                        model: {
                          value: _vm.form.field,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "field", $$v)
                          },
                          expression: "form.field",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "字段中文名", prop: "name" } },
                [
                  _c("Input", {
                    staticStyle: { width: "245px" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  staticStyle: { width: "345px" },
                  attrs: { label: "表格中显示", prop: "tableShow" },
                },
                [
                  _c(
                    "i-switch",
                    {
                      model: {
                        value: _vm.form.tableShow,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "tableShow", $$v)
                        },
                        expression: "form.tableShow",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  staticStyle: { width: "345px" },
                  attrs: { label: "可添加编辑", prop: "editable" },
                },
                [
                  _c(
                    "i-switch",
                    {
                      model: {
                        value: _vm.form.editable,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "editable", $$v)
                        },
                        expression: "form.editable",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.editable,
                      expression: "form.editable",
                    },
                  ],
                  attrs: { label: "字段表单类型", prop: "type" },
                },
                [
                  _c(
                    "Select",
                    {
                      staticStyle: { width: "245px" },
                      attrs: { filterable: "" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("Option", { attrs: { value: "text" } }, [
                        _vm._v("文本输入框"),
                      ]),
                      _c("Option", { attrs: { value: "textarea" } }, [
                        _vm._v("多行文本输入框"),
                      ]),
                      _c("Option", { attrs: { value: "select" } }, [
                        _vm._v("选择下拉框"),
                      ]),
                      _c("Option", { attrs: { value: "number" } }, [
                        _vm._v("数字输入框"),
                      ]),
                      _c("Option", { attrs: { value: "dict" } }, [
                        _vm._v("数据字典选择组件"),
                      ]),
                      _c("Option", { attrs: { value: "customList" } }, [
                        _vm._v("自定义列表选择组件"),
                      ]),
                      _c("Option", { attrs: { value: "date" } }, [
                        _vm._v("日期选择器"),
                      ]),
                      _c("Option", { attrs: { value: "datetime" } }, [
                        _vm._v("日期时间选择器"),
                      ]),
                      _c("Option", { attrs: { value: "daterange" } }, [
                        _vm._v("日期范围选择器"),
                      ]),
                      _c("Option", { attrs: { value: "upload" } }, [
                        _vm._v("图片上传输入组件"),
                      ]),
                      _c("Option", { attrs: { value: "uploadThumb" } }, [
                        _vm._v("图片上传缩略图组件"),
                      ]),
                      _c("Option", { attrs: { value: "switch" } }, [
                        _vm._v("Switch开关"),
                      ]),
                      _c("Option", { attrs: { value: "radio" } }, [
                        _vm._v("Radio单选框"),
                      ]),
                      _c("Option", { attrs: { value: "time" } }, [
                        _vm._v("时间选择器"),
                      ]),
                      _c("Option", { attrs: { value: "area" } }, [
                        _vm._v("省市县级联"),
                      ]),
                      _c("Option", { attrs: { value: "slider" } }, [
                        _vm._v("Slider滑块"),
                      ]),
                      _c("Option", { attrs: { value: "quill" } }, [
                        _vm._v("富文本组件Quill"),
                      ]),
                      _c("Option", { attrs: { value: "password" } }, [
                        _vm._v("密码强度提示输入框"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == "dict",
                      expression: "form.type == 'dict'",
                    },
                  ],
                  attrs: { label: "字典类型", prop: "dictType" },
                },
                [
                  _c("customList", {
                    staticStyle: { width: "245px" },
                    attrs: {
                      filterable: "",
                      url: "/dict/getAll",
                      valueBind: "type",
                      description: "type",
                    },
                    model: {
                      value: _vm.form.dictType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dictType", $$v)
                      },
                      expression: "form.dictType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == "customList",
                      expression: "form.type == 'customList'",
                    },
                  ],
                  attrs: { label: "自定义URL", prop: "customUrl" },
                },
                [
                  _c("Input", {
                    staticStyle: { width: "245px" },
                    attrs: { placeholder: "输入自定义选择组件url属性" },
                    model: {
                      value: _vm.form.customUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "customUrl", $$v)
                      },
                      expression: "form.customUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.type == "area",
                      expression: "form.type == 'area'",
                    },
                  ],
                  attrs: { label: "显示级别", prop: "level" },
                },
                [
                  _c(
                    "Select",
                    {
                      staticStyle: { width: "245px" },
                      model: {
                        value: _vm.form.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "level", $$v)
                        },
                        expression: "form.level",
                      },
                    },
                    [
                      _c("Option", { attrs: { value: "0" } }, [
                        _vm._v("仅显示省"),
                      ]),
                      _c("Option", { attrs: { value: "1" } }, [
                        _vm._v("显示省和县"),
                      ]),
                      _c("Option", { attrs: { value: "2" } }, [
                        _vm._v("显示省市县"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.editable,
                      expression: "form.editable",
                    },
                  ],
                  staticStyle: { width: "345px" },
                  attrs: { label: "是否必填", prop: "validate" },
                },
                [
                  _c(
                    "i-switch",
                    {
                      model: {
                        value: _vm.form.validate,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "validate", $$v)
                        },
                        expression: "form.validate",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("是"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("否"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  staticStyle: { width: "345px" },
                  attrs: { label: "可搜索", prop: "searchable" },
                },
                [
                  _c(
                    "i-switch",
                    {
                      model: {
                        value: _vm.form.searchable,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "searchable", $$v)
                        },
                        expression: "form.searchable",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.searchable,
                      expression: "form.searchable",
                    },
                  ],
                  attrs: { label: "搜索框类型", prop: "searchType" },
                },
                [
                  _c(
                    "Select",
                    {
                      staticStyle: { width: "245px" },
                      model: {
                        value: _vm.form.searchType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "searchType", $$v)
                        },
                        expression: "form.searchType",
                      },
                    },
                    [
                      _c("Option", { attrs: { value: "text" } }, [
                        _vm._v("文本输入框"),
                      ]),
                      _c("Option", { attrs: { value: "select" } }, [
                        _vm._v("选择下拉框"),
                      ]),
                      _c("Option", { attrs: { value: "dict" } }, [
                        _vm._v("数据字典选择组件"),
                      ]),
                      _c("Option", { attrs: { value: "customList" } }, [
                        _vm._v("自定义列表选择组件"),
                      ]),
                      _c("Option", { attrs: { value: "daterange" } }, [
                        _vm._v("日期范围选择器"),
                      ]),
                      _c("Option", { attrs: { value: "date" } }, [
                        _vm._v("日期选择器"),
                      ]),
                      _c("Option", { attrs: { value: "area" } }, [
                        _vm._v("省市县级联"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.searchType == "dict",
                      expression: "form.searchType == 'dict'",
                    },
                  ],
                  attrs: { label: "字典类型", prop: "searchDictType" },
                },
                [
                  _c("customList", {
                    staticStyle: { width: "245px" },
                    attrs: {
                      filterable: "",
                      url: "/dict/getAll",
                      valueBind: "type",
                      description: "type",
                    },
                    model: {
                      value: _vm.form.searchDictType,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "searchDictType", $$v)
                      },
                      expression: "form.searchDictType",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.searchType == "customList",
                      expression: "form.searchType == 'customList'",
                    },
                  ],
                  attrs: { label: "自定义URL", prop: "searchCustomUrl" },
                },
                [
                  _c("Input", {
                    staticStyle: { width: "245px" },
                    attrs: { placeholder: "输入自定义选择组件url属性" },
                    model: {
                      value: _vm.form.searchCustomUrl,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "searchCustomUrl", $$v)
                      },
                      expression: "form.searchCustomUrl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.searchType == "area",
                      expression: "form.searchType == 'area'",
                    },
                  ],
                  attrs: { label: "显示级别", prop: "searchLevel" },
                },
                [
                  _c(
                    "Select",
                    {
                      staticStyle: { width: "245px" },
                      model: {
                        value: _vm.form.searchLevel,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "searchLevel", $$v)
                        },
                        expression: "form.searchLevel",
                      },
                    },
                    [
                      _c("Option", { attrs: { value: "0" } }, [
                        _vm._v("仅显示省"),
                      ]),
                      _c("Option", { attrs: { value: "1" } }, [
                        _vm._v("显示省和县"),
                      ]),
                      _c("Option", { attrs: { value: "2" } }, [
                        _vm._v("显示省市县"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  staticStyle: { width: "345px" },
                  attrs: { label: "可排序", prop: "sortable" },
                },
                [
                  _c(
                    "i-switch",
                    {
                      model: {
                        value: _vm.form.sortable,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "sortable", $$v)
                        },
                        expression: "form.sortable",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.sortable,
                      expression: "form.sortable",
                    },
                  ],
                  staticStyle: { width: "345px" },
                  attrs: { label: "默认排序", prop: "defaultSort" },
                },
                [
                  _c(
                    "i-switch",
                    {
                      model: {
                        value: _vm.form.defaultSort,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "defaultSort", $$v)
                        },
                        expression: "form.defaultSort",
                      },
                    },
                    [
                      _c("span", { attrs: { slot: "open" }, slot: "open" }, [
                        _vm._v("开"),
                      ]),
                      _c("span", { attrs: { slot: "close" }, slot: "close" }, [
                        _vm._v("关"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.defaultSort && _vm.form.sortable,
                      expression: "form.defaultSort && form.sortable",
                    },
                  ],
                  attrs: { label: "默认排序方式", prop: "defaultSortType" },
                },
                [
                  _c(
                    "Select",
                    {
                      staticStyle: { width: "245px" },
                      model: {
                        value: _vm.form.defaultSortType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "defaultSortType", $$v)
                        },
                        expression: "form.defaultSortType",
                      },
                    },
                    [
                      _c("Option", { attrs: { value: "desc" } }, [
                        _vm._v("倒序(大到小)"),
                      ]),
                      _c("Option", { attrs: { value: "asc" } }, [
                        _vm._v("正序(小到大)"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                {
                  staticStyle: { width: "345px" },
                  attrs: { label: "排序值", prop: "sortOrder" },
                },
                [
                  _c("InputNumber", {
                    model: {
                      value: _vm.form.sortOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sortOrder", $$v)
                      },
                      expression: "form.sortOrder",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.modalVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "Button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("提交")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "生成代码",
            width: 1000,
            fullscreen: _vm.full,
            styles: _vm.full ? {} : _vm.modalStyle,
          },
          model: {
            value: _vm.codeModal,
            callback: function ($$v) {
              _vm.codeModal = $$v
            },
            expression: "codeModal",
          },
        },
        [
          _c(
            "RadioGroup",
            {
              staticStyle: { "margin-bottom": "15px" },
              on: { "on-change": _vm.changeRadio },
              model: {
                value: _vm.resultType,
                callback: function ($$v) {
                  _vm.resultType = $$v
                },
                expression: "resultType",
              },
            },
            [
              _c("Radio", { attrs: { label: "drawerApi", border: "" } }, [
                _vm._v("抽屉组件(含API)"),
              ]),
              _c("Radio", { attrs: { label: "drawer", border: "" } }, [
                _vm._v("抽屉组件(模拟请求)"),
              ]),
              _c("Radio", { attrs: { label: "singleApi", border: "" } }, [
                _vm._v("弹框(含API)"),
              ]),
              _c("Radio", { attrs: { label: "single", border: "" } }, [
                _vm._v("弹框(模拟请求)"),
              ]),
              _c("Radio", { attrs: { label: "componentApi", border: "" } }, [
                _vm._v("动态组件(含API)"),
              ]),
              _c("Radio", { attrs: { label: "component", border: "" } }, [
                _vm._v("动态组件(模拟请求)"),
              ]),
            ],
            1
          ),
          _c(
            "Tabs",
            {
              attrs: { type: "card" },
              on: { "on-click": _vm.changeCode },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("TabPane", {
                attrs: { name: "index.vue", label: "index.vue" },
              }),
              _vm.resultType == "drawerApi" || _vm.resultType == "drawer"
                ? _c("TabPane", {
                    attrs: { name: "addEdit.vue", label: "addEdit.vue" },
                  })
                : _vm._e(),
              _vm.resultType == "componentApi" || _vm.resultType == "component"
                ? _c("TabPane", {
                    attrs: { name: "add.vue", label: "add.vue" },
                  })
                : _vm._e(),
              _vm.resultType == "componentApi" || _vm.resultType == "component"
                ? _c("TabPane", {
                    attrs: { name: "edit.vue", label: "edit.vue" },
                  })
                : _vm._e(),
              _vm.resultType == "drawerApi" ||
              _vm.resultType == "componentApi" ||
              _vm.resultType == "singleApi"
                ? _c("TabPane", { attrs: { name: "api.js", label: "api.js" } })
                : _vm._e(),
            ],
            1
          ),
          !_vm.full
            ? _c("Input", {
                staticStyle: { "max-height": "60vh", overflow: "auto" },
                attrs: { type: "textarea", autosize: "" },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              })
            : _vm._e(),
          _vm.full
            ? _c("Input", {
                attrs: { rows: 32, type: "textarea" },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { icon: "md-expand" },
                  on: {
                    click: function ($event) {
                      _vm.full = !_vm.full
                    },
                  },
                },
                [_vm._v("全屏开/关")]
              ),
              _c(
                "Button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.code,
                      expression: "code",
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error",
                    },
                  ],
                  attrs: { type: "primary", icon: "md-copy" },
                },
                [_vm._v("复制代码 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            title: "自动读取字段",
            width: 700,
            fullscreen: _vm.fullEntity,
          },
          model: {
            value: _vm.entityModal,
            callback: function ($$v) {
              _vm.entityModal = $$v
            },
            expression: "entityModal",
          },
        },
        [
          _c("Alert", { attrs: { "show-icon": "" } }, [
            _vm._v(
              "输入实体类的引用路径，将自动读取其字段生成所需JSON配置数据，免去手动配置添加字段 "
            ),
          ]),
          _c(
            "Form",
            {
              ref: "entityForm",
              attrs: {
                model: _vm.entityForm,
                "label-width": 130,
                rules: _vm.entityFormValidate,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "实体类引用路径", prop: "path" } },
                [
                  _c(
                    "Row",
                    { attrs: { type: "flex", justify: "space-between" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "410px" },
                        attrs: {
                          placeholder: "例如：com.scmt.entity.User",
                          clearable: "",
                        },
                        model: {
                          value: _vm.entityForm.path,
                          callback: function ($$v) {
                            _vm.$set(_vm.entityForm, "path", $$v)
                          },
                          expression: "entityForm.path",
                        },
                      }),
                      _c(
                        "Button",
                        {
                          attrs: { type: "warning", icon: "md-play" },
                          on: { click: _vm.generateEntityData },
                        },
                        [_vm._v("读取字段 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.fullEntity
            ? _c("Input", {
                staticStyle: { "max-height": "50vh", overflow: "auto" },
                attrs: {
                  type: "textarea",
                  autosize: "",
                  placeholder: "生成结果",
                },
                model: {
                  value: _vm.entityData,
                  callback: function ($$v) {
                    _vm.entityData = $$v
                  },
                  expression: "entityData",
                },
              })
            : _vm._e(),
          _vm.fullEntity
            ? _c("Input", {
                attrs: { rows: 32, type: "textarea" },
                model: {
                  value: _vm.entityData,
                  callback: function ($$v) {
                    _vm.entityData = $$v
                  },
                  expression: "entityData",
                },
              })
            : _vm._e(),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { icon: "md-expand" },
                  on: {
                    click: function ($event) {
                      _vm.fullEntity = !_vm.fullEntity
                    },
                  },
                },
                [_vm._v("全屏开/关 ")]
              ),
              _c(
                "Button",
                {
                  attrs: {
                    type: "primary",
                    icon: "md-checkmark-circle-outline",
                  },
                  on: { click: _vm.submitEntityData },
                },
                [_vm._v("确认更新字段值 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }